define("ember-modifier/-private/class/modifier", ["exports", "@ember/application", "@ember/modifier", "ember-modifier/-private/class/modifier-manager", "@ember/destroyable"], function (_exports, _application, _modifier, _modifierManager, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
   * A base class for modifiers which need more capabilities than function-based
   * modifiers. Useful if, for example:
   *
   * 1. You need to inject services and access them
   * 2. You need fine-grained control of updates, either for performance or
   *    convenience reasons, and don't want to teardown the state of your modifier
   *    every time only to set it up again.
   * 3. You need to store some local state within your modifier.
   *
   * The lifecycle hooks of class modifiers are tracked. When they run, they any
   * values they access will be added to the modifier, and the modifier will
   * update if any of those values change.
   */
  class ClassBasedModifier {
    constructor(owner, args) {
      /**
       * The arguments passed to the modifier. `args.positional` is an array of
       * positional arguments, and `args.named` is an object containing the named
       * arguments.
       */
      _defineProperty(this, "args", void 0);
      /**
       * The element the modifier is applied to.
       *
       * @warning `element` is ***not*** available during `constructor` or
       *   `willDestroy`.
       */
      // SAFETY: this is managed correctly by the class-based modifier. It is not
      // available during the `constructor`.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      _defineProperty(this, "element", null);
      (0, _application.setOwner)(this, owner);
      this.args = args;
    }

    /**
     * Called when the modifier is installed **and** anytime the arguments are
     * updated.
     */
    didReceiveArguments() {
      /* no op, for subclassing */
    }

    /**
     * Called anytime the arguments are updated but **not** on the initial
     * install. Called before `didReceiveArguments`.
     */
    didUpdateArguments() {
      /* no op, for subclassing */
    }

    /**
     * Called when the modifier is installed on the DOM element. Called after
     * `didReceiveArguments`.
     */
    didInstall() {
      /* no op, for subclassing */
    }

    /**
     * Called when the DOM element is about to be destroyed; use for removing
     * event listeners on the element and other similar clean-up tasks.
     *
     * @deprecated since 2.0.0: prefer to use `willDestroy`, since both it and
     *   `willRemove` can perform all the same operations, including on the
     *   `element`.
     */
    willRemove() {
      /* no op, for subclassing */
    }

    /**
     * Called when the modifier itself is about to be destroyed; use for teardown
     * code. Called after `willRemove`.
     */
    willDestroy() {
      /* no op, for subclassing */
    }
    get isDestroying() {
      return (0, _destroyable.isDestroying)(this);
    }
    get isDestroyed() {
      return (0, _destroyable.isDestroyed)(this);
    }
  }
  _exports.default = ClassBasedModifier;
  (0, _modifier.setModifierManager)(owner => new _modifierManager.default(owner), ClassBasedModifier);
});