define("ember-gesture-modifiers/modifiers/did-pan", ["exports", "ember-modifier", "ember-gesture-modifiers/utils/parse-touch-data", "@ember/object"], function (_exports, _emberModifier, _parseTouchData, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const _fn = () => {};
  let DidPanModifier = _exports.default = (_class = class DidPanModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "threshold", void 0);
      _defineProperty(this, "axis", void 0);
      _defineProperty(this, "capture", void 0);
      _defineProperty(this, "preventScroll", void 0);
      _defineProperty(this, "pointerTypes", void 0);
      _defineProperty(this, "currentTouches", new Map());
      _defineProperty(this, "dragging", false);
    }
    addEventListeners() {
      // By default, CSS rule `touch-action` is `auto`, enabling panning on both directions.
      // We override panning on a given direction, so we need to disable default browser behavior
      // on that diretion, but we need to keep the other direction pannable.
      // Thus, we set `touch-action` to `pan-y` when we pan horizontally and vice versa.
      if (this.axis === 'horizontal') {
        this.element.style.touchAction = 'pan-y';
      } else if (this.axis === 'vertical') {
        this.element.style.touchAction = 'pan-x';
      } else if (this.axis === 'both') {
        this.element.style.touchAction = 'none';
      }
      this.element.addEventListener('pointerdown', this.didTouchStart, {
        capture: this.capture,
        passive: true
      });
      this.element.addEventListener('pointermove', this.didTouchMove, {
        capture: this.capture,
        passive: !this.preventScroll
      });
      this.element.addEventListener('pointerup', this.didTouchEnd, {
        capture: this.capture,
        passive: true
      });
      this.element.addEventListener('pointercancel', this.didTouchEnd, {
        capture: this.capture,
        passive: true
      });
      if (this.pointerTypes?.includes('mouse')) {
        document.addEventListener('pointermove', this.documentPointerMove, {
          capture: this.capture,
          passive: !this.preventScroll
        });
        document.addEventListener('pointercancel', this.documentPointerUp, {
          capture: this.capture,
          passive: true
        });
        document.addEventListener('pointerup', this.documentPointerUp, {
          capture: this.capture,
          passive: true
        });
      }
    }
    removeEventListeners() {
      this.element.style.touchAction = null;
      this.element.removeEventListener('pointerdown', this.didTouchStart, {
        capture: this.capture,
        passive: true
      });
      this.element.removeEventListener('pointermove', this.didTouchMove, {
        capture: this.capture,
        passive: !this.preventScroll
      });
      this.element.removeEventListener('pointerup', this.didTouchEnd, {
        capture: this.capture,
        passive: true
      });
      this.element.removeEventListener('pointercancel', this.didTouchEnd, {
        capture: this.capture,
        passive: true
      });
      if (this.pointerTypes?.includes('mouse')) {
        document.removeEventListener('pointermove', this.documentPointerMove, {
          capture: this.capture,
          passive: !this.preventScroll
        });
        document.removeEventListener('pointercancel', this.documentPointerUp, {
          capture: this.capture,
          passive: true
        });
        document.removeEventListener('pointerup', this.documentPointerUp, {
          capture: this.capture,
          passive: true
        });
      }
    }
    didTouchStart(e) {
      if (!this.dragging && this.pointerTypes.includes(e.pointerType)) {
        const touchData = (0, _parseTouchData.parseInitialTouchData)(e);
        this.currentTouches.set(e.pointerId, touchData);
        this.dragging = true;
      }
    }
    didTouchMove(e) {
      if (e.pointerType !== 'mouse') {
        this.handleTouchMove(e);
      }
    }
    didTouchEnd(e) {
      if (e.pointerType !== 'mouse') {
        this.handleTouchEnd(e);
      }
    }
    documentPointerMove(e) {
      if (this.dragging && e.pointerType === 'mouse' && this.pointerTypes.includes(e.pointerType)) {
        this.handleTouchMove(e);
      }
    }
    documentPointerUp(e) {
      if (this.dragging && e.pointerType === 'mouse' && this.pointerTypes.includes(e.pointerType)) {
        this.handleTouchEnd(e);
      }
    }
    handleTouchMove(e) {
      if (this.dragging && this.currentTouches.has(e.pointerId)) {
        const previousTouchData = this.currentTouches.get(e.pointerId);
        const touchData = (0, _parseTouchData.parseTouchData)(previousTouchData, e);
        if (touchData.panStarted) {
          // prevent scroll if a pan is still busy
          if (this.preventScroll) {
            e.preventDefault();
          }
          this.didPan(touchData.data);
        } else {
          console.log(touchData);
          // only pan when the threshold for the given axis is achieved
          if (!touchData.panDenied && (this.axis === 'horizontal' && Math.abs(touchData.data.current.distanceX) > this.threshold || this.axis === 'vertical' && Math.abs(touchData.data.current.distanceY) > this.threshold || this.axis === 'both' && Math.abs(touchData.data.current.distance) > this.threshold)) {
            // test if axis matches with data else deny the pan
            if (this.axis === 'horizontal' && (0, _parseTouchData.isHorizontal)(touchData) || this.axis === 'vertical' && (0, _parseTouchData.isVertical)(touchData) || this.axis === 'both') {
              // prevent scroll if a pan is detected
              if (this.preventScroll) {
                e.preventDefault();
              }
              touchData.panStarted = true;

              // trigger panStart hook
              this.didPanStart(touchData.data);
            } else {
              touchData.panDenied = true;
            }
          }
        }
        this.currentTouches.set(e.pointerId, touchData);
      }
    }
    handleTouchEnd(e) {
      if (this.dragging && this.currentTouches.has(e.pointerId)) {
        this.dragging = false;
        const previousTouchData = this.currentTouches.get(e.pointerId);
        const touchData = (0, _parseTouchData.parseTouchData)(previousTouchData, e);
        if (touchData.panStarted) {
          this.didPanEnd(touchData.data);
        }
        this.currentTouches.delete(e.pointerId);
      }
    }
    didReceiveArguments() {
      this.removeEventListeners();
      this.threshold = this.args.named.threshold ?? 10;
      this.axis = this.args.named.axis ?? 'horizontal';
      this.capture = this.args.named.capture ?? false;
      this.preventScroll = this.args.named.preventScroll ?? true;
      this.pointerTypes = this.args.named.pointerTypes ?? ['touch'];
      this.didPanStart = this.args.named.onPanStart ?? _fn;
      this.didPan = this.args.named.onPan ?? _fn;
      this.didPanEnd = this.args.named.onPanEnd ?? _fn;
      this.addEventListeners();
    }
    willRemove() {
      this.removeEventListeners();
      this.currentTouches.clear();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "didTouchStart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didTouchStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didTouchMove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didTouchMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didTouchEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didTouchEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "documentPointerMove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "documentPointerMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "documentPointerUp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "documentPointerUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchMove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchEnd"), _class.prototype)), _class);
});