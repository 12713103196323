define("ember-cli-server-variables/services/server-variables", ["exports", "@ember/utils", "@ember/string", "@ember/service"], function (_exports, _utils, _string, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ServerVariablesService extends _service.default {
    unknownProperty(serverVar) {
      var ENV = this.env;
      var prefix = ENV.serverVariables.tagPrefix || ENV.modulePrefix;
      var dasherizedVar = (0, _string.dasherize)(serverVar);

      // ensure we don't die in fastboot by checking if document exists
      var tag = document ? document.querySelector(`head meta[name=${prefix}-${dasherizedVar}]`) : null;
      var content = tag ? tag.content : null;
      if (!(0, _utils.isBlank)(content)) {
        try {
          return JSON.parse(content);
        } catch (e) {
          // content was not JSON
          return content;
        }
      }
    }
  }
  _exports.default = ServerVariablesService;
});